import _random from "./random";
import _math from "./math";
import _penplot from "./penplot.js";
import _geometry from "./geometry.js";
import _shader from "./shader.js";
import _color from "./color.js";
var exports = {};
exports = {
  random: _random,
  math: _math,
  penplot: _penplot,
  geometry: _geometry,
  shader: _shader,
  color: _color
};
export default exports;
export const random = exports.random;